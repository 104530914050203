import $ from 'jquery';
import './styles.css';

class Clickable {
    constructor (element) {
        this.el = $(element);
        this.initialize();
    }

    initialize () {
        if (this.href) {
            this.el.attr('data-href', '');
        }
    }

    get href () {
        let href = this.el.data('href-link');

        if (0 === href.length) {
            let button = this.el.find('a:first');

            if (button.length && button.attr('href').length) {
                return button.attr('href');
            }
        }

        return href;
    }

    get target () {
        let button = this.el.find('a:first');

        if (button.length) {
            let targetAttr = button.attr('target');

            if (typeof targetAttr !== 'undefined' && targetAttr !== false && targetAttr.length) {
                return button.attr('target');
            }
        }

        return '_self';
    }

    onClick () {
        let href = this.href;
        if (href) {
            window.open(href, this.target);
        }
    }
}

$('[data-href-link]').each(function () {
    $(this).data('clickable', new Clickable(this));
});

$(document).on('click tap', '[data-href-link]', function (e) {
    e.preventDefault();

    let clickable = $(this).data('clickable') || new Clickable(this);
    clickable.onClick();
});
