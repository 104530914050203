import $ from 'jquery';
import './in-viewport';
import './styles/animate-fade-in';
import './styles/animate-grow-in';
import './styles/animate-partners';
import './styles/animate-slide-in';
import './styles/animate-zoom-in';

let animateables = $([
    '.animate-fade-in',
    '.animate-grow-in',
    '.animate-partners',
    '.animate-slide-in',
    '.animate-slide-in-left',
    '.animate-slide-in-right',
    '.animate-zoom-in',
].join(', '));

animateables.each(function (i, element) {
    let el = $(element);
    if (el.visible(true)) {
        el.addClass('already-visible');
    }
});

$(window).on('scroll touchend load', function () {
    animateables.each(function (i, element) {
        let el = $(element);
        if (el.visible(true)) {
            el.addClass('come-in');
        }
    });
});
