let pageYOffset = 50;

let $window = $(window);
let $html = $('html');
let $body = $('body');
let $hamburger = $('.hamburger');

$('[data-menu-toggle]').on('click', function (e) {
    e.preventDefault();

    let pageYOffsetCached = window.pageYOffset;

    if ($(this).hasClass('content-overlay')) {
        $html.removeClass('-menu-opened -basket-is-open');
        $hamburger.removeClass('is-active');
    } else {
        $html.toggleClass('-menu-opened');
        $hamburger.toggleClass('is-active');

        let isOpened = $html.hasClass('-menu-opened');

        if (isOpened) {
            pageYOffset = pageYOffsetCached;
            $body.css({ top: -pageYOffset + 'px' });
        } else {
            $window.scrollTop(pageYOffset);
        }
    }
});
