// import 'es6-promise'; // IE >= 10 support for sweetalert2
import Swal from 'sweetalert2';

// Set Sweet Alert defaults
Swal.mixin({
    cancelButtonText: 'Annuleren',
    confirmButtonColor: '#596f5d',
    cancelButtonColor: '#d3d3d3',
    confirmButtonText: 'OK!'
});

export default Swal;
