$(() => {
    let segments = window.location.pathname.split('/');
    let href = '/' + segments[1];

    $.each(['[id="menu"]'], (i, selector) => {
        let linkElem = $(selector + ' a[href^="' + href + '"]:first');

        if (linkElem.length) {
            let parentLi = linkElem.closest('li').addClass('current');
            let upperLi = parentLi.closest('ul').parent();
            if (upperLi.length) {
                upperLi.addClass('current');
            }
        }
    });
});
