import './jquery.unveil';
import './styles.scss';

$(() => {

    $('img[data-src]').unveil(0, function () {
        $(this).load(function () {
            this.style.opacity = 1;
        });
    });

});
