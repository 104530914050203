import $ from 'jquery';
import { scrollTo } from '../utils';

let $document = $(document);

function scrollToHash(hash) {
    let elem = getElementByHash(hash);
    if (elem.length) {
        scrollTo(elem.offset().top - 60);
    }
}

function getElementByHash(hash) {
    return $('[data-anchor="' + hash + '"]');
}

$document.on('click touchstart', '[data-scroll-to]', function (event) {
    event.preventDefault();
    let $this = $(this);
    let elem = $($this.data('scroll-to'));
    if (elem.length) {
        scrollTo(elem.offset().top - ($this.data('scroll-to-correction') || -10));
    }
});

// If element with hash exists on page, scroll to it.
// Otherwise follow link.
$document.on('click touchstart', 'a[href*="#"]', function (event) {
    let hash = this.href.split('#')[1];

    if (getElementByHash(hash).length) {
        scrollToHash(hash);
        event.preventDefault();
    }
});

$(() => {
    setTimeout(() => {
        // Smooth scroll to anchor
        if (window.location.hash.match(/^#/)) {
            scrollToHash(window.location.hash.substr(1));
        }
    }, 500);
});
