import $ from 'jquery';

const utils = {
    /**
     * Parse the validation error from the server into a flattened array of messages.
     *
     * @param  {Object}  error  The error object in JSON format.
     *
     * @return {Array.<String>}
     */
    parseValidationError (error) {
        return Object.keys(error).reduce((messages, field) => messages.concat(error[field]), []);
    },

    /**
     * Scroll to given offset.
     *
     * @param  {Integer}  offset  The page offset.
     * @param  {Integer}  speed  The animation duration.
     *
     * @return {Void}
     */
    scrollTo (offset, speed = 1000) {
        $('html, body').animate({
            scrollTop: Math.max(0, offset - 100)
        }, speed);
    }
};

export let parseValidationError = utils.parseValidationError;
export let scrollTo = utils.scrollTo;

export default utils;
