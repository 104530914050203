/*global require*/
import $ from 'jquery';
import { csrf_token } from './js/core';

// Styles
import './sass/styles.scss';

// Scripts
import './js/modernizr';
import 'picturefill';
import 'es6-promise/auto';

import './js/analytics';
import './js/animations';
import './js/layout';
import './js/menu';
import './js/scroll-helpers';
import './js/data-href-link';

// Whooohoo, lets go!
let ElementRoutes = {
    'img[data-src]' () { require('./js/unveil'); },
    '.cartoons' () {
        require.ensure([], () => {
            require('./js/cartoons');
        });
    },
    '.categories-box' () { require('./js/categories-box'); },
    '.custom-select' () { require('./js/custom-select'); },
    '.gallery' () { require('./js/photoswipe'); },
    '.contact-forms' () {
        require.ensure([], () => {
            require('./js/contact-forms');
        });
    },
    'form.form-generic' () { require('./js/forms/generic'); },
    '[id="popup"]' () {  require('./js/popup.js'); },
};

$.each(ElementRoutes, (key, callback) => {
    if ($(key).length) {
        callback();
        console.info('✓ ' + key);
    }
});

// CSRF Header to ajax request
$.ajaxSetup({
    headers: { 'X-CSRF-TOKEN': csrf_token() }
});

// Trigger click helper
$(document).on('click touchstart', '[data-trigger-click]', function (e) {
    try {
        e.preventDefault();
        $(this.getAttribute('data-trigger-click')).trigger('click');
    } catch (e) {
        //
    }
});

// Test for iPad or iPhone
if (navigator.userAgent.match(/(iPad|iPhone)/g)) {
    $('html').addClass('-is-ios');
}

// Op touchdevices niet bij scrollen de gallery openen
document.querySelectorAll('.photosbox').forEach(item => {
    item.addEventListener('touchstart', event => {
        event.stopPropagation()
    })
})

// Autofocus search
setTimeout(() => {
    const input = $('.gsc-input[name="search"]')
    input.length > 0 && input.focus()
}, 500)
