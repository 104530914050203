import $ from 'jquery';

let $window = $(window);
let $document = $(document);
let $html = $('html');

// Scroll
$(function () {
    $window.on('scroll resize load', function () {
        let scroll = $window.scrollTop();
        let position = parseInt($window.scrollTop() + $window.height());
        let minPosition = parseInt($document.height() - 300);

        $html.toggleClass('-page-is-scrolled-down', scroll > 0);
        $html.toggleClass('-should-show-scroll-up', scroll > 200 && position > minPosition);
    });

    $('.boxy2-aside img').each(function (index, element) {
        let $img = $(element);
        let src = $img.attr('src');
        $img.parent()
            .css('background-image', 'url(' + src + ')')
            .siblings('.boxy2-aside')
            .css('background-image', 'url(' + src.replace('-xs.jpg', '.jpg') + ')');
    });

    $('.list3 .item-image img').each(function (index, element) {
        let $img = $(element);
        let src = $img.attr('src');
        $img.parent().css('background-image', 'url(' + src + ')');
    });
});

// Loading class
$document.ajaxStart(() => {
    $html.addClass('-has-xhr-activity');
}).ajaxComplete(() => {
    $html.removeClass('-has-xhr-activity');
});
